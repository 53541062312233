import { accountInitialState } from "./account/reducer";
import { appInitialState } from "./app/reducer";
import { roomInitialState } from "./room/reducer";
import { dictionaryInitialState } from "./dictionary/reducer";
import { suggestionInitialState } from "./suggestion/reducer";

export const initialState = {
  account: accountInitialState,
  app: appInitialState,
  room: roomInitialState,
  dictionary: dictionaryInitialState,
  suggestion: suggestionInitialState,
};

export type State = typeof initialState;
