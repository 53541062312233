import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { defineMessage } from "react-intl";

// O means unlimited
export const LanguageFreePLANS = {
  free: {
    title: defineMessage({
      id: "label/free-room",
      defaultMessage: "Free Room",
    }),
    code: "free",
    price: 0,
    point: 0,
    rooms: 1,
    peoples: defineMessage({
      id: "label/max-2-people",
      defaultMessage: "Max 2 people",
    }),
    minutes: defineMessage({
      id: "label/max-40-minutes",
      defaultMessage: "Max 40 minutes",
    }),
    features: [
      defineMessage({
        id: "text/free-up-to-40-minutes",
        defaultMessage: "Free for up to <b>40 minutes daily</b>",
      }),
      defineMessage({
        id: "text/max-2-participants",
        defaultMessage: "Maximum <b>2 participants</b>",
      }),
    ],
  },
  pro: {
    title: defineMessage({
      id: "label/pro-room",
      defaultMessage: "Pro Room",
    }),
    code: "pro",
    price: 9800,
    point: 9800,
    rooms: 0,
    peoples: defineMessage({
      id: "label/max-10-people",
      defaultMessage: "Max 10 people",
    }),
    minutes: defineMessage({
      id: "label/unlimited",
      defaultMessage: "Unlimited",
    }),
    features: [
      defineMessage({
        id: "text/no-time-limit",
        defaultMessage: "<b>No time limit</b>",
      }),
      defineMessage({
        id: "text/up-to-10-participants",
        defaultMessage: "Up to <b>10 participants</b>",
      }),
      defineMessage({
        id: "text/automatic-stt",
        defaultMessage: "Automatic <b>speech-to-text record</b>",
      }),
    ],
  },
  security: {
    title: defineMessage({
      id: "label/secure-room",
      defaultMessage: "Secure Room",
    }),
    code: "security",
    price: 39800,
    point: 39800,
    rooms: 0,
    peoples: defineMessage({
      id: "label/max-10-people",
      defaultMessage: "Max 10 people",
    }),
    minutes: defineMessage({
      id: "label/unlimited",
      defaultMessage: "Unlimited",
    }),
    features: [
      defineMessage({
        id: "text/no-time-limit",
        defaultMessage: "<b>No time limit</b>",
      }),
      defineMessage({
        id: "text/up-to-10-participants",
        defaultMessage: "Up to <b>10 participants</b>",
      }),
      defineMessage({
        id: "text/automatic-stt",
        defaultMessage: "Automatic <b>speech-to-text record</b>",
      }),
      defineMessage({
        id: "text/security-setting",
        defaultMessage: "<b>Security</b> settings",
      }),
    ],
  },
} as const;

export const SelfExplorePLAN = {
  title: defineMessage({
    id: "label/selfhost",
    defaultMessage: "SelfHost",
  }),
  code: "selfExplore",
  point: 3000,
  price: 3000,
  features: [
    defineMessage({
      id: "text/experience-tour-spots-without-guide",
      defaultMessage: "Experience tour spots without a guide",
    }),
  ],
};

export const TherapyPLAN = {
  title: defineMessage({
    id: "label/therapy",
    defaultMessage: "Therapy",
  }),
  code: "therapy",
  point: 500,
  price: 500,
  features: [
    defineMessage({
      id: "text/possible-to-experience-therapy",
      defaultMessage: "It is possible to experience therapy",
    }),
  ],
};

export const PartyPLANS = {
  free: {
    title: defineMessage({
      id: "label/free",
      defaultMessage: "Free",
    }),
    code: "free",
    price: 0,
    point: 0,
    features: [
      defineMessage({
        id: "text/free-up-to-40-minutes",
        defaultMessage: "Free for up to <b>40 minutes daily</b>",
      }),
      defineMessage({
        id: "text/max-2-participants",
        defaultMessage: "Maximum <b>2 participants</b>",
      }),
    ],
  },
  pro: {
    title: defineMessage({
      id: "label/vr-party",
      defaultMessage: "Party",
    }),
    code: "pro",
    price: 900,
    point: 900,
    features: [
      defineMessage({
        id: "text/no-time-limit",
        defaultMessage: "<b>No time limit</b>",
      }),
      defineMessage({
        id: "text/max-10-participants",
        defaultMessage:
          "No Participant restriction<b> (Up to 10 people is recommended)</b>",
      }),
    ],
  },
} as const;

export const LANGUAGE_OPTIONS = [
  { value: "en", label: "English" },
  { value: "ja", label: "日本語" },
  { value: "zh-CN", label: "简体中文" },
  { value: "zh-TW", label: "繁體中文" },
];

export const AVAILABLE_LANGUAGES = [
  { value: "en", label: "English" },
  { value: "ja", label: "日本語" },
  { value: "zh-CN", label: "简体中文" },
  { value: "zh-TW", label: "繁體中文" },
  { value: "vi", label: "Tiếng Việt" },
  { value: "es", label: "Español" },
  { value: "nl", label: "Nederlands" },
  { value: "fr", label: "Français" },
  { value: "ru", label: "Ру́сский язы́к" },
  { value: "pt", label: "Português" },
  { value: "zh-HK", label: "廣東話" },
];

export const SPOT_LIST = [
  {
    code: "AF",
    name: "Africa",
  },
  {
    code: "AS",
    name: "Asia",
  },
  {
    code: "EU",
    name: "Europe",
  },
  {
    code: "NA",
    name: "North America",
  },
  {
    code: "OC",
    name: "Oceania",
  },
  {
    code: "SA",
    name: "South America",
  },
];

export const STRIPE_COUNTRY_LIST = [
  {
    value: "AU",
    label: "Austraila (AU)",
    currencies: ["AUD"],
  },
  {
    value: "AT",
    label: "Austria (AT)",
    currencies: ["EUR"],
  },
  {
    value: "BG",
    label: "Bulgaria (BG)",
    currencies: ["EUR", "BGN"],
  },
  {
    value: "CA",
    label: "Canada (CA)",
    currencies: ["CAD", "USD"],
  },
  {
    value: "FI",
    label: "Finland (FI)",
    currencies: ["EUR"],
  },
  {
    value: "FR",
    label: "France (FR)",
    currencies: ["EUR"],
  },
  {
    value: "DE",
    label: "Germany (DE)",
    currencies: ["EUR"],
  },
  {
    value: "HU",
    label: "Hungary (HU)",
    currencies: ["HUF", "EUR"],
  },
  {
    value: "IT",
    label: "Italy (IT)",
    currencies: ["EUR"],
  },
  {
    value: "JP",
    label: "Japan (JP)",
    currencies: ["JPY"],
  },
  {
    value: "NZ",
    label: "New Zealand (NZ)",
    currencies: ["NZD"],
  },
  {
    value: "SG",
    label: "Singapore (SG)",
    currencies: ["SGD"],
  },
  {
    value: "ES",
    label: "Spain (ES)",
    currencies: ["EUR"],
  },
  {
    value: "NL",
    label: "Netherlands (NL)",
    currencies: ["EUR"],
  },
  {
    value: "PL",
    label: "Poland (PL)",
    currencies: ["EUR", "PLN"],
  },
  {
    value: "PT",
    label: "Portugal (PT)",
    currencies: ["EUR"],
  },
  {
    value: "RO",
    label: "Romania (RO)",
    currencies: ["EUR", "RON"],
  },
  {
    value: "CH",
    label: "Switzerland (CH)",
    currencies: ["EUR", "CHF"],
  },
  {
    value: "AE",
    label: "United Arab Emirates (AE)",
    currencies: ["AED"],
  },
  {
    value: "GB",
    label: "United Kingdom (GB)",
    currencies: ["EUR", "GBP"],
  },
  {
    value: "US",
    label: "United States (US)",
    currencies: ["USD"],
  },
];

export const MONTHS_OPTIONS = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const INITIAL_SPOT_COUNTRY_OPTIONS: {
  [key: string]: CheckboxValueType[];
} = {
  AF: [],
  AN: [],
  AS: [],
  EU: [],
  NA: [],
  OC: [],
  SA: [],
};

export const INITIAL_SPOT_COUNTRY_COUNT_OPTIONS: {
  [key: string]: number;
} = {
  AF: 0,
  AN: 0,
  AS: 0,
  EU: 0,
  NA: 0,
  OC: 0,
  SA: 0,
};

export const DATE_FORMAT = "YYYYMMDD";
export const DISPLAY_DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HHmmss";
export const DISPLAY_TIME_FORMAT = "hh:mm a";
