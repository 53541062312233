import dayjs from "dayjs";

export const isValidExpiry = (value: string) =>
  /^(0[1-9]|1[0-2])\/([0-9]{4}|[0-9]{2})$/.test(value);

export const isValidCVC = (value: string) => /^[0-9]{3,4}$/.test(value);

export const isValidEmail = (value: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );

const validUrlRegex =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
export const isValidUrl = (value: string) => validUrlRegex.test(value);

const validRouteRegex = /(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
export const isValidRoute = (value: string) => validRouteRegex.test(value);

export const isValidNumber = (value: string) => /^[0-9]*\.?[0-9]*$/.test(value);

export const validatePassword = (value: string) => {
  if (!value) return -2;
  if (value.length < 8) return -1;
  if (value !== value.trim()) return 0;

  return 1;
};

export const isValidDateTime = (
  dateTime: string | undefined,
  format: string
) => {
  if (!dateTime || dateTime === "Invalid Date") {
    return false;
  }

  return dayjs(dateTime, format).format(format) === dateTime;
};

export const renderValidParsedDateTime = (
  dateTime: string | string[] | null,
  format: string
) => {
  if (typeof dateTime === "string" && isValidDateTime(dateTime, format)) {
    return dayjs(dateTime, format).format(format);
  }

  return undefined;
};
