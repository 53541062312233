import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { initSentry } from "./utils/sentry";
import ReactGA from "react-ga";
import { GOOGLE_TRACKING_ID } from "./routes/constants";
import { getStripeInstance } from "./utils/stripe";
import startUp from "./utils/startUp";

ReactGA.initialize(GOOGLE_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const stripe = getStripeInstance();

initSentry();
startUp();

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripe}>
      <Provider store={store}>
        <App />
      </Provider>
    </Elements>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
