import { Action } from "redux";

import {
  API_USER_INFO,
  USER_LOGIN_ROUTE_PATH,
  API_USER_LOGIN,
  API_USER_FORGOT_PASSWORD,
  API_USER_RESET_PASSWORD,
  API_USER_REGISTER,
  API_USER_PERMISSION,
  API_USER_PRO_ADMIN_PERMISSION,
  API_USER_POINT,
  API_CARD,
  API_PLAN,
  API_EMAIL_CHANGE,
  API_CONFIRM_EMAIL_CHANGE,
  API_EMAIL_VERIFY,
  LANGUAGE_FREE_ROUTE_PATH,
  API_SUBSCRIBE_PLAN_WITH_FREE_POINT,
  API_USER_PAYMENT_METHOD,
  API_POINT_HISTORY,
  API_USER_CONTINUE_WITH_GOOGLE,
  API_USER_CONTINUE_WITH_FACEBOOK,
} from "../../routes/constants";
import { history } from "../../routes/history";
import { createRequest } from "../../utils/network";
import { resetStore } from "../actions";
import { setLoading } from "../app/actions";
import { AppThunk } from "../types";
import {
  AccountTypes,
  IPaymentMethod,
  PointHistories,
  SubscribePlanWithFreePoint,
  User,
  UserPoint,
} from "./types";

import { getAuthToken } from "../../utils";
import { redirectToRefer } from "../../utils/helpter";
import { notification } from "antd";

interface SetUserAction extends Action {
  readonly type: AccountTypes.SET_USER;
  readonly payload: { user: User };
}

interface SetUserPermissionAction extends Action {
  readonly type: AccountTypes.SET_USER_PERMISSION;
  readonly payload: { isAdmin: boolean };
}

interface SetUserProAdminPermissionAction extends Action {
  readonly type: AccountTypes.SET_USER_PRO_ADMIN_PERMISSION;
  readonly payload: { user: User };
}

interface SetPlanAction extends Action {
  readonly type: AccountTypes.SET_PLAN;
  readonly payload: string;
}

interface SetIsSubmitting extends Action {
  readonly type: AccountTypes.SET_IS_SUBMITTING;
  readonly payload: boolean;
}

interface SetRedirectPathAction extends Action {
  readonly type: AccountTypes.SET_REDIRECT_PATH;
  readonly payload: { redirectPath: string | null };
}

interface SetAuthenticatedAction extends Action {
  readonly type: AccountTypes.SET_AUTHENTICATE;
  readonly payload: { authenticated: boolean };
}

interface LogoutAction extends Action {
  readonly type: AccountTypes.LOGOUT;
}

interface GetUserPointAction extends Action {
  readonly type: AccountTypes.GET_USERPOINT;
  readonly payload: { user: UserPoint };
}

interface GetUserPointHistoriesAction extends Action {
  readonly type: AccountTypes.GET_USERPOINT_HISTORY;
  readonly payload: { pointHistories: PointHistories };
}

interface SetPaymentMethod extends Action {
  readonly type: AccountTypes.SET_PAYMENT_METHOD;
  readonly payload: IPaymentMethod | null;
}

export interface SetAccountErrorsAction extends Action {
  type: AccountTypes.SET_ERRORS;
  payload: any[];
}
export interface ChangePointBySelfAction extends Action {
  type: AccountTypes.SUBSCRIBE_PLAN_WITH_FREE_POINT;
  payload: any[];
}

export interface UserAuthRequestAction extends Action {
  type: AccountTypes.USER_AUTH_REQUEST;
}

export interface UserAuthSuccessAction extends Action {
  type: AccountTypes.USER_AUTH_SUCCESS;
}

export function fetchUser(): AppThunk {
  return async (dispatch: Function) => {
    dispatch(setLoading(true));

    let tryAttempts = 3;

    async function fetchUserWithAttempts() {
      const { response, error } = await createRequest(API_USER_INFO);

      if (response.success) {
        dispatch(setAuthenticated(true));
        dispatch(setUser(response.data.user));
        dispatch(fetchPaymentMethod());
        if (!response.data.user.language) {
          let language = localStorage.getItem("language") || "en";
          dispatch(updateUser({ language }, () => {}));
        }
      }

      if (error) {
        if (tryAttempts > 0) {
          tryAttempts -= 1;
          await fetchUserWithAttempts();
        } else {
          dispatch(logout());
        }
      }
    }

    await fetchUserWithAttempts();

    dispatch(setLoading(false));
  };
}

export function setUser(user: User): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_USER,
      payload: { user },
    });
  };
}

export function setUserPermission(isAdmin: boolean): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_USER_PERMISSION,
      payload: { isAdmin },
    });
  };
}

export function setUserProAdminPermission(user: User): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_USER_PRO_ADMIN_PERMISSION,
      payload: { user },
    });
  };
}

export function setRedirectPath(redirectPath: string | null) {
  return {
    type: AccountTypes.SET_REDIRECT_PATH,
    payload: { redirectPath },
  };
}

export function setIsSubmitting(isSubmitting: boolean) {
  return {
    type: AccountTypes.SET_IS_SUBMITTING,
    payload: { isSubmitting },
  };
}

export function setErrors(errors: string[]) {
  return {
    type: AccountTypes.SET_ERRORS,
    payload: errors,
  };
}

export function setAuthenticated(authenticated: boolean) {
  return {
    type: AccountTypes.SET_AUTHENTICATE,
    payload: { authenticated },
  };
}

export function setUserLogout(): AppThunk {
  return (dispatch) => {
    dispatch({
      type: AccountTypes.LOGOUT,
    });

    dispatch(resetStore());
  };
}

export function logout(): AppThunk {
  return async (dispatch) => {
    dispatch(setLoading(true));

    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    dispatch(setUserLogout());
    history.push(USER_LOGIN_ROUTE_PATH);

    dispatch(setLoading(false));
  };
}

export function requestLogin(
  email: string,
  password: string,
  referer: string,
  routeReferer: string,
  prevLocation: string
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const { response } = await createRequest(API_USER_LOGIN, {
      method: "POST",
      body: JSON.stringify({
        currentLoginOption: "email",
        email,
        password,
      }),
    });

    if (response.success) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user.id);
      dispatch(setUser(response.data.user));
      dispatch(setAuthenticated(true));
      dispatch(fetchPaymentMethod());

      if (prevLocation) {
        history.push(prevLocation);
      } else if (referer) {
        redirectToRefer(referer, response.data.token);
      } else if (routeReferer) {
        history.push(routeReferer);
      } else {
        history.push(LANGUAGE_FREE_ROUTE_PATH);
      }

      return dispatch({
        type: AccountTypes.USER_AUTH_SUCCESS,
      });
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestRegister(
  email: string,
  name: string,
  password: string,
  referer: string,
  routeReferer: string,
  prevLocation: string
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const { response } = await createRequest(API_USER_REGISTER, {
      method: "POST",
      body: JSON.stringify({
        currentRegisterOption: "email",
        email,
        name,
        password,
      }),
    });

    if (response.success) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user.id);
      dispatch(setUser(response.data.user));
      dispatch(setAuthenticated(true));

      if (prevLocation) {
        history.push(prevLocation);
      } else if (referer) {
        redirectToRefer(referer, response.data.token);
      } else if (routeReferer) {
        history.push(routeReferer);
      } else {
        history.push(LANGUAGE_FREE_ROUTE_PATH);
      }

      return dispatch({
        type: AccountTypes.USER_AUTH_SUCCESS,
      });
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

function continueWithSuccessResponse(
  response: any,
  dispatch: Function,
  referer: string = "",
  routeReferer: string = "",
  prevLocation?: string
) {
  localStorage.setItem("token", response.data.token);
  localStorage.setItem("userId", response.data.user.id);
  dispatch(setUser(response.data.user));
  dispatch(setAuthenticated(true));
  dispatch(fetchPaymentMethod());

  if (prevLocation) {
    history.push(prevLocation);
  } else if (referer) {
    redirectToRefer(referer, response.data.token);
  } else if (routeReferer) {
    history.push(routeReferer);
  } else {
    history.push(LANGUAGE_FREE_ROUTE_PATH);
  }
}

export function continueWithGoogle(
  tokenId: string | null,
  errors: any[] = [],
  errorMessage: { message: string; description: string },
  referer: string = "",
  routeReferer: string = "",
  prevLocation?: string
): AppThunk {
  return async (dispatch: Function) => {
    if (!tokenId) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: errors,
      });
    }

    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const { response, error } = await createRequest(
      API_USER_CONTINUE_WITH_GOOGLE,
      {
        method: "POST",
        body: JSON.stringify({ tokenId }),
      }
    );

    if (error) {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
      });
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      continueWithSuccessResponse(
        response,
        dispatch,
        referer,
        routeReferer,
        prevLocation
      );
      return dispatch({
        type: AccountTypes.USER_AUTH_SUCCESS,
      });
    } else {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
      });
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function continueWithGoogleIdentity(
  token: string,
  user: User,
  referer: string = "",
  routeReferer: string = "",
  prevLocation?: string
): AppThunk {
  return (dispatch: Function) => {
    // we are not calling API here, just managing state in respect to consistent code

    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const response = {
      data: {
        token,
        user,
      },
    };

    continueWithSuccessResponse(
      response,
      dispatch,
      referer,
      routeReferer,
      prevLocation
    );

    return dispatch({
      type: AccountTypes.USER_AUTH_SUCCESS,
    });
  };
}

export function continueWithFacebook(
  payload: {
    userID: string;
    accessToken: string;
  } | null,
  errors: any[] = [],
  errorMessage: { message: string; description: string },
  referer: string = "",
  routeReferer: string = "",
  prevLocation?: string
): AppThunk {
  return async (dispatch: Function) => {
    if (!payload) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: errors,
      });
    }

    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const { response, error } = await createRequest(
      API_USER_CONTINUE_WITH_FACEBOOK,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );

    if (error) {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
      });
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      continueWithSuccessResponse(
        response,
        dispatch,
        referer,
        routeReferer,
        prevLocation
      );
      return dispatch({
        type: AccountTypes.USER_AUTH_SUCCESS,
      });
    } else {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
      });
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function confirmEmail(token: string, onSuccess?: () => any): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });

    const { response } = await createRequest(API_EMAIL_VERIFY, {
      method: "POST",
      body: JSON.stringify({ token }),
    });

    if (response.success) {
      localStorage.setItem("token", response.data.token);
      dispatch(setUser(response.data.user));
      dispatch(setAuthenticated(true));
      onSuccess && onSuccess();
      return;
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestForgotPassword(
  email: string,
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });

    const { response } = await createRequest(API_USER_FORGOT_PASSWORD, {
      method: "POST",
      body: JSON.stringify({
        currentForgotPasswordOption: "email",
        email,
      }),
    });

    if (response.success) {
      onSuccess && onSuccess();
      return;
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestResetPassword(
  password: string,
  token: string,
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });

    const { response } = await createRequest(API_USER_RESET_PASSWORD, {
      method: "PUT",
      body: JSON.stringify({ password, token }),
    });

    if (response.success) {
      onSuccess && onSuccess();
      return;
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestPlanUpdate(
  service: string,
  planCode: string,
  cardInfo?: {
    cardNumber: string;
    expiry: string;
    cvc: string;
  },
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(API_PLAN, {
      method: "POST",
      body: JSON.stringify({ cardInfo, planCode, service }),
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      onSuccess && onSuccess();
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

function generateUserFormdata(data: {
  email?: string;
  name?: string;
  language?: string;
  isBusiness?: boolean;
  file?: any;
  isFirst?: boolean;
}) {
  const formData = new FormData();

  data.name && formData.append("name", data.name);
  data.email && formData.append("email", data.email);
  data.language && formData.append("language", data.language);
  data.isBusiness &&
    formData.append("isBusiness", JSON.stringify(data.isBusiness));
  if (data.hasOwnProperty("isFirst")) {
    formData.append("isFirst", data.isFirst ? "true" : "false");
  }
  data.file && formData.append("file", data.file);

  return formData;
}

export function updateUser(
  data: {
    email?: string;
    name?: string;
    language?: string;
    isBusiness?: boolean;
    file?: any;
    isFirst?: boolean;
  },
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const formData = generateUserFormdata(data);
    const { response, error } = await createRequest(API_USER_INFO, {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${getAuthToken() || ""}`,
      },
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      dispatch(setUser(response.data.user));
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function updateUserPermission(
  data: { isAdmin: boolean },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(API_USER_PERMISSION, {
      method: "PUT",
      body: JSON.stringify(data),
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      dispatch(setUserPermission(data.isAdmin));
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function updateUserProAdminPermission(
  data: { isProAdmin: boolean },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(
      API_USER_PRO_ADMIN_PERMISSION,
      {
        method: "PUT",
        body: JSON.stringify(data),
      }
    );

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      dispatch(setUserProAdminPermission(response.data.user));
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function updateCardInfo(
  data: {
    cardNumber: string;
    expiry: string;
    cvc: string;
  },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(API_CARD, {
      method: "POST",
      body: JSON.stringify(data),
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      onSuccess && onSuccess();
      return dispatch({
        type: AccountTypes.SET_PAYMENT_METHOD,
        payload: response.data,
      });
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function requestEmailChange(onSuccess: () => any): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(API_EMAIL_CHANGE, {
      method: "PUT",
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function confirmEmailChange(
  data: {
    token: string;
    email: string;
  },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(API_CONFIRM_EMAIL_CHANGE, {
      method: "PUT",
      body: JSON.stringify(data),
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function getUserPoint(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    const { response } = await createRequest(API_USER_POINT, {
      method: "GET",
    }); //Similar for points history just need to change api_user_point to the api hsitoyr point on

    if (response.success) {
      return dispatch({
        type: AccountTypes.GET_USERPOINT, //same for here
        payload: response.data,
      });
    }
    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function getUserPoinHistory(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    const { response } = await createRequest(API_POINT_HISTORY, {
      method: "GET",
    }); //Similar for points history just need to change api_user_point to the api hsitoyr point on

    if (response.success) {
      return dispatch({
        type: AccountTypes.GET_USERPOINT_HISTORY, //same for here
        payload: response.data,
      });
    }
    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function subscribePlanWithFreePoint(
  postData: SubscribePlanWithFreePoint
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({ type: AccountTypes.SUBSCRIBE_PLAN_WITH_FREE_POINT });
    const { response } = await createRequest(
      API_SUBSCRIBE_PLAN_WITH_FREE_POINT,
      {
        method: "POST",
        body: JSON.stringify(postData),
      }
    );
    if (response.success) {
      return dispatch({
        type: AccountTypes.SUBSCRIBE_PLAN_WITH_FREE_POINT,
        payload: response.data,
      });
    }
    return dispatch({
      type: AccountTypes.SET_ERRORS,
    });
  };
}

export function fetchPaymentMethod(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    const { response } = await createRequest(API_USER_PAYMENT_METHOD, {
      method: "GET",
    });

    if (response.success) {
      return dispatch({
        type: AccountTypes.SET_PAYMENT_METHOD,
        payload: response.data,
      });
    }
    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export type AccountActions =
  | SetUserAction
  | SetUserPermissionAction
  | SetUserProAdminPermissionAction
  | SetPlanAction
  | SetIsSubmitting
  | SetRedirectPathAction
  | SetAuthenticatedAction
  | LogoutAction
  | SetAccountErrorsAction
  | GetUserPointAction
  | GetUserPointHistoriesAction
  | ChangePointBySelfAction
  | SetPaymentMethod
  | UserAuthRequestAction
  | UserAuthSuccessAction;
