import { Action } from "redux";
import { notification } from "antd";

import { AppActions, ChangePointAdminPro, PointEmail } from "./types";
import { AppThunk } from "../types";
import { updateUser } from "../account/actions";

import { Language } from "../../i18n";
import {
  API_POINT_SEARCH_EMAIL,
  API_POINT_CHANGE_PROADMIN,
  API_SEND_CONTACT,
} from "../../routes/constants";
import { createRequest } from "../../utils/network";

export interface SetLoadingAction extends Action {
  type: AppActions.SET_LOADING;
  payload: boolean;
}

export interface SetLanguageAction extends Action {
  type: AppActions.SET_LANGUAGE;
  payload: Language;
}

export interface GetPointEmailSuccessAction extends Action {
  type: AppActions.GET_POINT_EMAIL_SUCCESS;
  payload: PointEmail;
}

export type AppActionTypes =
  | SetLoadingAction
  | SetLanguageAction
  | GetPointEmailSuccessAction;

export function setLoading(payload: boolean): SetLoadingAction {
  return { type: AppActions.SET_LOADING, payload };
}

export function setLanguage(payload: Language, notAuth = false): AppThunk {
  return async (dispatch: Function) => {
    if (!notAuth) {
      dispatch(updateUser({ language: payload }, () => {}));
    }
    dispatch({
      type: AppActions.SET_LANGUAGE,
      payload: payload,
    });
  };
}

export function getPointEmail(searchEmail: string): AppThunk {
  return async (dispatch: Function) => {
    dispatch({ type: AppActions.GET_POINT_EMAIL });
    const sendData = new URLSearchParams({ email: searchEmail });
    const { response } = await createRequest(
      `${API_POINT_SEARCH_EMAIL}?${sendData}`,
      {
        method: "GET",
      }
    );
    if (response.success) {
      return dispatch({
        type: AppActions.GET_POINT_EMAIL_SUCCESS,
        payload: response.data.pointByEmail,
      });
    }
    return dispatch({
      type: AppActions.GET_POINT_EMAIL_ERROR,
      payload: response.errors,
    });
  };
}

export function changePointProAdmin(
  postData: ChangePointAdminPro,
  infoMsgSuccess: string,
  infoMsgError: string
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({ type: AppActions.POST_POINT_CHANGE_PROADMIN });
    const { response } = await createRequest(API_POINT_CHANGE_PROADMIN, {
      method: "POST",
      body: JSON.stringify(postData),
    });
    if (response.success) {
      notification.success({
        message: infoMsgSuccess,
      });
      return dispatch({
        type: AppActions.POST_POINT_CHANGE_PROADMIN_SUCCESS,
      });
    }
    notification.error({
      message: infoMsgError,
    });
    return dispatch({
      type: AppActions.POST_POINT_CHANGE_PROADMIN_ERROR,
    });
  };
}

export async function sendContactRequest(data: {
  company: string;
  name: string;
  email: string;
  phone: string;
  inquiry: string;
}) {
  const { response } = await createRequest(API_SEND_CONTACT, {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (!response.success) {
    notification.error({
      message: response.errors[0].message,
    });
    return false;
  }

  return true;
}
