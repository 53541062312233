import { ResourceState } from "../store/types";
import { getAuthToken } from "./index";

export async function createRequest<T extends {} = any>(
  url: string,
  options?: RequestInit
) {
  let response = {} as T;
  let error = null;

  const defaultOptions: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken() || ""}`,
    },
  };

  const initOptions: RequestInit = {
    mode: "cors",
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...options?.headers,
    },
    ...options,
  };

  try {
    const res = await fetch(url, initOptions);

    response = await res.json();
  } catch (e) {
    error = e;
    console.error("Network error: ", error);
  }

  return { response, error };
}

export async function getJson(path: string, params?: Record<string, any>) {
  const query = (params && `?${new URLSearchParams(params).toString()}`) ?? "";

  return await createRequest(path + query, { method: "GET" });
}

export async function postJson<T = any>(
  path: string,
  body: Record<string, any> = {}
) {
  return await createRequest<T>(path, {
    method: "POST",
    body: JSON.stringify(body),
  });
}

export function isResourceLoading(status: ResourceState): boolean {
  return status === ResourceState.LOADING;
}

export function isResourceNotFoundOrLoading(status: ResourceState): boolean {
  return status === ResourceState.NOT_FOUND || status === ResourceState.LOADING;
}

export function isResourceFound(status: ResourceState): boolean {
  return status === ResourceState.FOUND;
}
