import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { notification } from "antd";

import IntroContainer from "../../Landing/components/IntroContainer";
import { USER_LOGIN_ROUTE_PATH } from "../../../routes/constants";
import { accountErrors } from "../../../store/account/selectors";

import "../style.scss";

const ForgotPasswordSuccess = () => {
  const apiErrors = useSelector(accountErrors);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (apiErrors.length > 0) {
      notification.error({
        message: formatMessage({
          id: "forgot-pwd-success/forgot-pwd-error",
          defaultMessage: "Forgot Password Error",
        }),
        description: apiErrors[0].message || apiErrors[0],
        duration: 1.5,
      });
    }
  }, [apiErrors, formatMessage]);

  return (
    <IntroContainer isBlur={true}>
      <div className="center-block">
        <p className="form-title">
          <FormattedMessage
            id="forgot-pwd-success/new-pwd-sent"
            defaultMessage="We have reissued the password to the email address you entered."
          />
        </p>
        <br />
        <Link
          className=""
          title={formatMessage({
            id: "forgot-pwd-success/close-label",
            defaultMessage: "Close",
          })}
          to={USER_LOGIN_ROUTE_PATH}
        >
          &times;
          <FormattedMessage
            id="forgot-pwd-success/close-label"
            defaultMessage="Close"
          />
        </Link>
      </div>
    </IntroContainer>
  );
};

export default ForgotPasswordSuccess;
