import React, { lazy, Suspense } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { Loader } from "../components/Loader/Loader";
import { history } from "./history";
import { PublicRoute } from "./PublicRoute";
import * as routes from "./constants";
import { PrivateRoute } from "./PrivateRoute";
import ForgotPasswordSuccess from "../pages/auth/ForgotPasswordSuccess";
import useUserAuth from "../utils/useUserAuth";
import { LastLocationProvider } from "react-router-last-location";

const Login = lazy(() => import("../pages/auth/Login"));
const Home = lazy(() => import("../pages/Landing/Home"));
const ContactUs = lazy(() => import("../pages/Landing/ContactUs"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));
const Register = lazy(() => import("../pages/auth/Register"));
const UserSetting = lazy(() => import("../pages/user/UserSetting"));
const UserAdmin = lazy(() => import("../pages/admin/UserAdmin"));
const ProAdminDashboard = lazy(
  () => import("../pages/ProAdmin/ProAdminDashboard")
);
const LanguageFree = lazy(() => import("../pages/LanguageFree"));
const PaymentPlan = lazy(() => import("../pages/PaymentPlan"));
const PrivacyPolicyJP = lazy(
  () => import("../pages/Landing/PrivacyPolicy/PrivacyPolicyJP")
);
const PrivacyPolicyEN = lazy(
  () => import("../pages/Landing/PrivacyPolicy/PrivacyPolicyEN")
);
const TermsUseJP = lazy(() => import("../pages/Landing/TermsUse/TermUseJP"));
const TermsUseEN = lazy(() => import("../pages/Landing/TermsUse/TermUseEN"));
const SCTLawJP = lazy(() => import("../pages/Landing/SCTLaw/SCTLawJP"));
const SCTLawEN = lazy(() => import("../pages/Landing/SCTLaw/SCTLawEN"));
const EmailConfirm = lazy(() => import("../pages/EmailConfirm"));
const Dictionary = lazy(() => import("../pages/Dictionary"));
const ResetEmail = lazy(() => import("../pages/ResetEmail"));
const FAQ = lazy(() => import("../pages/Landing/FAQ"));

export function AppRouter() {
  useUserAuth();

  return (
    <Suspense fallback={<Loader />}>
      <Router history={history}>
        <LastLocationProvider>
          <Switch>
            <PublicRoute exact path="/" component={Home} redirect />
            <PublicRoute
              exact
              path={routes.CONTACT_US_PATH}
              component={ContactUs}
            />
            <Route
              exact
              path={routes.USER_CONFIRM_EMAIL}
              component={EmailConfirm}
            />
            <Route
              exact
              path={routes.USER_RESET_EMAIL_ROUTE_PATH}
              component={ResetEmail}
            />
            <PublicRoute
              exact
              path={routes.PRIVACY_POLICY_ROUTE_PATH}
              component={PrivacyPolicyJP}
            />
            <PublicRoute
              exact
              path={routes.PRIVACY_POLICY_ROUTE_PATH_EN}
              component={PrivacyPolicyEN}
            />
            <PublicRoute
              exact
              path={routes.TERMS_USE_ROUTE_PATH}
              component={TermsUseJP}
            />
            <PublicRoute
              exact
              path={routes.TERMS_USE_ROUTE_PATH_EN}
              component={TermsUseEN}
            />
            <PublicRoute
              exact
              path={routes.SCT_LAW_ROUTE_PATH}
              component={SCTLawJP}
            />
            <PublicRoute
              exact
              path={routes.SCT_LAW_ROUTE_PATH_EN}
              component={SCTLawEN}
            />
            <PublicRoute
              exact
              path={routes.USER_LOGIN_ROUTE_PATH}
              component={Login}
              hasReferer
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_REGISTER_ROUTE_PATH}
              component={Register}
              hasReferer
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_FORGOT_PASS_ROUTE_PATH}
              component={ForgotPassword}
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_FORGOT_PASS_SUCCESS_ROUTE_PATH}
              component={ForgotPasswordSuccess}
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_RESET_PASS_ROUTE_PATH}
              component={ResetPassword}
              redirect
            />
            <PublicRoute exact path={routes.FAQ_PATH} component={FAQ} />
            <PublicRoute
              exact
              path={routes.LANGUAGE_FREE_ROUTE_PATH}
              component={LanguageFree}
              noAuthDoor={true}
            />
            <PrivateRoute
              exact
              path={routes.USER_SETTING_ROUTE_PATH}
              component={UserSetting}
            />
            <PublicRoute
              path={routes.PRICES_ROUTE_PATH}
              component={PaymentPlan}
              noAuthDoor={true}
            />
            <PrivateRoute
              exact
              path={routes.ADMIN_ROUTE_PATH}
              component={UserAdmin}
            />
            <PrivateRoute
              exact
              path={routes.PRO_ADMIN_DASHBOARD_PATH}
              component={ProAdminDashboard}
            />
            <PrivateRoute
              exact
              path={routes.DICTIONARY_ROUTE_PATH}
              component={Dictionary}
            />
          </Switch>
        </LastLocationProvider>
      </Router>
    </Suspense>
  );
}
