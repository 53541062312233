// page routes
export const USER_LOGIN_ROUTE_PATH = "/user/login";
export const USER_CONFIRM_EMAIL = "/user/email-confirmation";
export const USER_FORGOT_PASS_ROUTE_PATH = "/user/forgot-password";
export const USER_FORGOT_PASS_SUCCESS_ROUTE_PATH =
  "/user/forgot-password-success";
export const USER_RESET_PASS_ROUTE_PATH = "/user/reset-password";
export const USER_RESET_EMAIL_ROUTE_PATH = "/user/reset-email";
export const USER_REGISTER_ROUTE_PATH = "/user/register";
export const USER_SETTING_ROUTE_PATH = "/user/setting";

export const ADMIN_ROUTE_PATH = "/admin";
export const PRO_ADMIN_DASHBOARD_PATH = "/admin/pro";

export const LANGUAGE_FREE_ROUTE_PATH = "/rooms";
export const PRICES_ROUTE_PATH = "/prices";
export const DICTIONARY_ROUTE_PATH = "/dictionary";

export const PRIVACY_POLICY_ROUTE_PATH = "/privacy-policy";
export const PRIVACY_POLICY_ROUTE_PATH_EN = "/privacy-policy-en";
export const TERMS_USE_ROUTE_PATH = "/terms-use";
export const TERMS_USE_ROUTE_PATH_EN = "/terms-use-en";
export const SCT_LAW_ROUTE_PATH = "/specified-commercial";
export const SCT_LAW_ROUTE_PATH_EN = "/specified-commercial-en";
export const FAQ_PATH = "/faq";
export const CONTACT_US_PATH = "/contact-us";

export const NOTIFICATION_ROUTE_PATH = "/notification";

// api paths
const API_BASE_URL = `${process.env.REACT_APP_BACKEND_HOST}/api`;
const API_BASE_URL_PUBLIC = process.env.REACT_APP_BACKEND_HOST;
const API_BASE_URL_NOT_AUTH = `${process.env.REACT_APP_BACKEND_HOST}/notAuth`;

export const API_CURRENCY_URL = `${process.env.REACT_APP_BACKEND_HOST}/currency`;

export const API_USER_REGISTER = `${API_BASE_URL}/user/register`;
export const API_USER_LOGIN = `${API_BASE_URL}/user/login`;
export const API_USER_CONTINUE_WITH_GOOGLE = `${API_BASE_URL}/user/continueWithGoogle`;
export const API_USER_CONTINUE_WITH_GOOGLE_IDENTITY = `${API_BASE_URL}/user/continueWithGoogleIdentity`;
export const API_USER_CONTINUE_WITH_FACEBOOK = `${API_BASE_URL}/user/continueWithFacebook`;
export const API_USER_FORGOT_PASSWORD = `${API_BASE_URL}/user/forgot-password`;
export const API_USER_RESET_PASSWORD = `${API_BASE_URL}/user/reset-password`;
export const API_USER_INFO = `${API_BASE_URL}/user`;
export const API_USER_PERMISSION = `${API_BASE_URL}/user/permission`;
export const API_USER_PRO_ADMIN_PERMISSION = `${API_BASE_URL}/user/permission/pro`;
export const API_USER_POINT = `${API_BASE_URL}/point/getPointBySelf`;
export const API_USER_PAYMENT_METHOD = `${API_BASE_URL}/user/payment-method`;
export const API_EMAIL_CHANGE = `${API_BASE_URL}/user/email`;
export const API_CONFIRM_EMAIL_CHANGE = `${API_BASE_URL}/user/confirm-email-change`;
export const API_EMAIL_VERIFY = `${API_BASE_URL}/user/email-verify`;
export const API_SEND_CONTACT = `${API_BASE_URL_PUBLIC}/send-contact`;

export const API_USERS_SEARCH_DETAILS = `${API_BASE_URL}/users/searchDetails`;

export const API_SOUVENIRS = `${API_BASE_URL}/items`;
export const API_ROOMS = `${API_BASE_URL}/tokbox/rooms`;
export const API_ROOM_HISTORY = `${API_BASE_URL}/freeRoom/history`;

export const API_VIDEO = `${API_BASE_URL}/hosting/video`;
export const API_GET_FAVOURITE_VIDEOS = `${API_BASE_URL}/hosting/video/list`;
export const API_UPDATE_VIDEO_HOST = `${API_BASE_URL}/hosting/video/addOwner`;

export const API_VIDEOS = `${API_BASE_URL}/videos`;
export const API_EXPLORE_PLACES = `${API_BASE_URL}/explorePlaces`;
export const API_EXPLORE_PLACES_PUBLIC = `${API_BASE_URL_PUBLIC}/explorePlaces`;
export const API_EXPLORE_PLACE_HISTORY = `${API_BASE_URL}/explorePlaceHistory`;
export const API_REVIEW_LIKE = `${API_BASE_URL}/review/like`;
export const API_PARTY_ROOMS = `${API_BASE_URL}/partyRooms`;
export const API_THERAPY_ROOMS = `${API_BASE_URL}/therapyRooms`;
export const API_THERAPY_ROOMS_PUBLIC = `${API_BASE_URL_PUBLIC}/therapyRooms`;
export const API_STREAMING_TEST_VIDEO = `${API_BASE_URL}/videos/streamingTest`;
export const API_STREAMING_TEST_VIDEO_PUBLIC = `${API_BASE_URL_PUBLIC}/videos/streamingTest/public`;
export const API_TRIAL_DOOR_VIDEO = `${API_BASE_URL}/setting/trialTrippingUrl`;

export const API_DICTIONARY = `${API_BASE_URL}/dictionary`;
export const API_HOSTING = `${API_BASE_URL}/hosting`;
export const API_PAYMENT = `${API_BASE_URL}/payment`;
export const API_NOT_AUTH_PAYMENT = `${API_BASE_URL_NOT_AUTH}/payment`;
export const API_NOT_AUTH_USER = `${API_BASE_URL_NOT_AUTH}/user`;

export const API_CARD = `${API_BASE_URL}/card`;
export const API_PLAN = `${API_BASE_URL}/plan`;
// Travel API
export const API_TOUR = `${API_BASE_URL}/tour`;
export const API_TOUR_PUBLIC = `${API_BASE_URL_PUBLIC}/tour`;
export const API_RECTOURS = `${API_BASE_URL}/tour/list`;
export const API_TRAVEL_REGISTRATION = `${API_BASE_URL}/register/tour`;
export const API_TEMPORARY = `${API_BASE_URL}/tour/temporary`;

export const API_HOSTGUIDE = `${API_BASE_URL}/tour/guide/host/`;
export const API_HOSTGUIDE_PUBLIC = `${API_BASE_URL_PUBLIC}/tour/guide/host/`;
export const API_HOSTGUIDE_EDIT = `${API_BASE_URL}/tour/guide/host/edit`;

export const API_TOURINRESERVATION = `${API_BASE_URL}/tour/guest/list/booking`;
export const API_HISTORYTOUR = `${API_BASE_URL}/tour/guest/list/history`;
export const API_HISTORYTOUR_WITH_NOSHOW = `${API_BASE_URL}/tour/guest/list/history-with-no-show`;
export const API_TOURREVIEW = `${API_BASE_URL}/tour/guest/booking`;

export const API_BOOKING_FOR_REPAY = `${API_BASE_URL}/tour/guest/list/history-with-no-show`;

export const API_CATEGORIES = `${API_BASE_URL}/tour/getCategories`;
export const API_SCHEDULE_LIMITATION = `${API_BASE_URL}/tour/checkItineraryTime`;

// YC Point
export const API_POINT_SEARCH_EMAIL = `${API_BASE_URL}/point/getPointByEmail`;
export const API_POINT_CHANGE_PROADMIN = `${API_BASE_URL}/point/changePointByProAdmin`;
export const API_POINT_HISTORY = `${API_BASE_URL}/point/history`;
export const API_SUBSCRIBE_PLAN_WITH_FREE_POINT = `${API_BASE_URL}/point/subscribePlanWithFreePoint`;
export const API_USER_POINT_HISTORY = `${API_BASE_URL}/hosting/payment/account/pointBalance`;

export const API_BUY_ITINERARY_WITH_FREE_POINT = `${API_BASE_URL}/payment/itinerary/buyWithFreePoint`;
export const API_BUY_FREE_ITINERARY = `${API_BASE_URL}/payment/itinerary/buyFreeItinerary`;
export const API_BUY_NOT_AUTH_FREE_ITINERARY = `${API_BASE_URL_NOT_AUTH}/payment/itinerary/buyFreeItinerary`;

// My page API
export const API_MYPAGE = `${API_BASE_URL}/mypage`;
// Google Analytics Tracking ID
export const GOOGLE_TRACKING_ID = `${process.env.REACT_APP_GOOGLE_TRACKING_ID}`;

// Invites API
export const API_SEND_EMAIL_INVITES = `${API_BASE_URL}/invite/send-email-invites`;
export const API_INVITE_LONG_URL = `${API_BASE_URL_PUBLIC}/invite/long-url`;

// Friends API
export const API_FRIEND = `${API_BASE_URL}/friend`;
export const API_FRIEND_LIST = `${API_BASE_URL}/friend/list`;
export const API_FRIEND_USER_DETAILS = `${API_BASE_URL}/friend/userDetails`;

// Notifications API
export const API_NOTIFICATIONS = `${API_BASE_URL}/notifications`;

// Music API
export const API_MUSIC = `${API_BASE_URL_PUBLIC}/music/concerts`;
export const API_MUSIC_SINGLE = `${API_BASE_URL_PUBLIC}/music/concert`;
export const API_MUSIC_PURCHASED = `${API_BASE_URL}/music/ticketPurchases`;
export const API_MUSIC_BUY = `${API_BASE_URL}/payment/concert/buy`;

// Guides API
export const API_GUIDES = `${API_BASE_URL}/guides`;
export const API_FAVORITE_GUIDES = `${API_BASE_URL}/favorite-guides`;

// ScheduleRequestHistory API
export const API_SCHEDULE_REQUEST_HISTORY = `${API_BASE_URL}/scheduleRequestHistory`;

// Spots API
export const API_SPOTS_MULTI_SELECT = `${API_BASE_URL_PUBLIC}/spots/multi-select`;
export const API_SPOTS_SPOT_NAMES = `${API_BASE_URL_PUBLIC}/spots/spot-names`;

// Countries API
export const API_COUNTRIES_COUNT = `${API_BASE_URL_PUBLIC}/countries/count`;
export const API_COUNTRIES_COUNTRY_NAMES = `${API_BASE_URL_PUBLIC}/countries/country-names`;

// Unique Suggestion API
export const API_UNIQUE_SUGGESTION = `${API_BASE_URL_PUBLIC}/unique-suggestions`;

// Reviews by Video API
export const API_REVIEWS_BY_VIDEO = `${API_BASE_URL_PUBLIC}/reviews/video`;
