import { SideMenuInterface } from "../components/MainLayout/types";

export const redirectToRefer = (referer: string, token: string) => {
  let updated = referer.replace(new RegExp("[?&#]+$"), "");
  updated += (referer.split("?")[1] ? "&" : "?") + "token=" + token;
  window.location.href = updated;
};

export const getMenuPath = (menus: SideMenuInterface[], value: string) => {
  let paths: string[] = [];
  let found = false;

  const searchKeyFromMenu = (ms: SideMenuInterface[]) => {
    for (let i = 0; i < ms.length; i++) {
      const menu = ms[i];

      paths.push(menu.key);

      if (menu.key === value) {
        found = true;
        break;
      }

      if (menu.subMenus) {
        searchKeyFromMenu(menu.subMenus);

        if (found) {
          break;
        }
      }

      paths.pop();
    }
  };

  searchKeyFromMenu(menus);
  return paths;
};

export const convertS3toCloud = (url: string) => {
  const prodS3 = process.env.REACT_APP_STAGING_VIDEO_S3_LINK;
  const stagingS3 = process.env.REACT_APP_PROD_VIDEO_S3_LINK;
  const cloudDomain = process.env.REACT_APP_TRIPPING_VIDEO_CLOUD_LINK;

  if (!cloudDomain) return url;

  if (prodS3 && url.includes(prodS3)) {
    return url.replace(prodS3, cloudDomain);
  } else if (stagingS3 && url.includes(stagingS3)) {
    return url.replace(stagingS3, cloudDomain);
  }

  return url;
};

export const isObjectEmpty = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isObjectOfArrayItemsEmpty = (obj: { [key: string]: any[] }) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value.length > 0) {
        return false;
      }
    }
  }
  return true;
};

export const compareObjectsOfArrayItemsLength = (
  obj1: { [key: string]: any[] },
  obj2: { [key: string]: any[] }
) => {
  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      const value = obj1[key];
      if (!obj2[key] || value.length !== obj2[key].length) {
        return false;
      }
    }
  }
  return true;
};
