import { createSelector } from "reselect";
import { State } from "../state";
import { AppState } from "./reducer";

export function getApp(state: State) {
  return state.app;
}

export const getLanguage = createSelector(
  getApp,
  (app: AppState) => app.language
);

export const isLoading = createSelector(getApp, (app: AppState) => app.loading);

export const getPointEmailSelector = createSelector(
  getApp,
  (app: AppState) => app.pointEmail
);
