export enum AppActions {
  SET_LANGUAGE = "app/SET_LANGUAGE",
  SET_LOADING = "app/SET_LOADING",
  GET_POINT_EMAIL = "tour/GET_POINT_EMAIL",
  GET_POINT_EMAIL_SUCCESS = "tour/GET_POINT_EMAIL_SUCCESS",
  GET_POINT_EMAIL_ERROR = "tour/GET_POINT_EMAIL_FAILURE",
  POST_POINT_CHANGE_PROADMIN = "POST_POINT_CHANGE_PROADMIN",
  POST_POINT_CHANGE_PROADMIN_SUCCESS = "POST_POINT_CHANGE_PROADMIN_SUCCESS",
  POST_POINT_CHANGE_PROADMIN_ERROR = "POST_POINT_CHANGE_PROADMIN_ERROR",
}

export interface ChangePointAdminPro {
  userId: number;
  isFree: boolean;
  eventCode: string;
  amountChange: number;
  memo: string;
  expirationDate: string;
}

export interface PointEmail {
  userId: number;
  name: string;
  email: string;
  remainingPoint: number;
}
