import { combineReducers } from "redux";
import { State } from "./state";
import { accountReducer } from "./account/reducer";
import { appReducer } from "./app/reducer";
import { roomReducer } from "./room/reducer";
import { dictionaryReducer } from "./dictionary/reducer";
import { suggestionReducer } from "./suggestion/reducer";

export const rootReducers = combineReducers<State>({
  account: accountReducer,
  app: appReducer,
  room: roomReducer,
  dictionary: dictionaryReducer,
  suggestion: suggestionReducer,
});
