import { RoomsActionTypes } from "./actions";
import {
  RoomActions,
  RoomInterface,
  RegisterTourVideo,
  VUMSVideo,
  PartyRoomInterface,
  TherapyRoomInterface,
  StreamingTestVideo,
  TrialVideo,
  IHistory,
  FavouriteVideo,
} from "./types";

export const roomInitialState = {
  isSubmitting: false,
  rooms: [] as RoomInterface[],
  selectedVideo: null as FavouriteVideo | VUMSVideo | null,
  videos: [] as FavouriteVideo[],
  countVideos: 0,
  registerTourVideo: {} as RegisterTourVideo,
  errors: [] as any[],
  countVUMSVideos: 0,
  vumsVideos: [] as VUMSVideo[],
  partyRooms: [] as PartyRoomInterface[],
  therapyRooms: [] as TherapyRoomInterface[],
  streamingTestVideo: {} as StreamingTestVideo,
  streamingTestVideoPublic: {} as StreamingTestVideo,
  trialvideo: { video: "" } as TrialVideo,
  countTrialVideos: 0,
  histories: {
    data: [],
    total: 0,
  } as {
    data: IHistory[];
    total: number;
  },
};

export type RoomState = typeof roomInitialState;

export function roomReducer(
  state: RoomState = roomInitialState,
  action: RoomsActionTypes
): RoomState {
  switch (action.type) {
    case RoomActions.GET_ROOMS:
      return {
        ...state,
        rooms: (action.payload || []).map((r) => ({
          ...r,
          key: r.id,
        })),
      };
    case RoomActions.GET_SELECTED_VIDEO:
      return {
        ...state,
        selectedVideo: action.payload,
      };
    case RoomActions.GET_FAVOURITE_VIDEOS:
      return {
        ...state,
        videos: action.payload.videos,
        countVideos: action.payload.total,
      };
    case RoomActions.GET_REGISTER_TOUR_VIDEO:
      return {
        ...state,
        registerTourVideo: action.payload.video,
      };
    case RoomActions.SET_ERRORS:
      return {
        ...state,
        errors: action.payload as any[],
      };
    case RoomActions.CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case RoomActions.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case RoomActions.CREATE_ROOM:
      return {
        ...state,
        rooms: [
          ...state.rooms,
          {
            ...action.payload,
            key: action.payload.id,
          },
        ],
      };
    case RoomActions.UPDATE_ROOM:
      return {
        ...state,
        rooms: state.rooms.map((el) => {
          if (el.id === action.payload.id) {
            return {
              ...action.payload,
              key: action.payload.id,
            };
          }

          return el;
        }),
      };
    case RoomActions.DELETE_ROOM:
      return {
        ...state,
        rooms: state.rooms.filter((e) => e.id !== action.payload.id),
      };
    case RoomActions.GET_VUMS_VIDEOS:
      return {
        ...state,
        vumsVideos: action.payload.videos,
        countVUMSVideos: action.payload.total,
      };
    case RoomActions.GET_STREAMING_TEST_VIDEO_PUBLIC:
      return {
        ...state,
        streamingTestVideoPublic: action.payload.video,
      };
    case RoomActions.GET_STREAMING_TEST_VIDEO:
      return {
        ...state,
        streamingTestVideo: action.payload.video,
      };
    case RoomActions.SET_STREAMING_TEST_VIDEO:
      return {
        ...state,
        streamingTestVideo: action.payload.video,
      };
    case RoomActions.GET_PARTY_ROOMS:
      return {
        ...state,
        partyRooms: (action.payload || []).map((r) => ({
          ...r,
          key: r.id,
        })),
      };
    case RoomActions.GET_THERAPY_ROOMS:
      return {
        ...state,
        therapyRooms: (action.payload || []).map((r) => ({
          ...r,
          key: r.id,
        })),
      };
    case RoomActions.SET_TRIAL_VIDEO:
      return { ...state, trialvideo: action.payload || {} };

    case RoomActions.SET_ROOM_HISTORY:
      return {
        ...state,
        histories: action.payload,
      };
    default:
      return state;
  }
}
