import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link, useLocation } from "react-router-dom";
import { Menu, Dropdown, Select, Row, Col, Drawer } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { Avatar } from "antd";
import { UserOutlined, AppstoreFilled, LoginOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { useGoogleLogout } from "react-google-login";
import { setLanguage } from "../../store/app/actions";
import { getLanguage } from "../../store/app/selectors";
import { getUser } from "../../store/account/selectors";
import { logout, getUserPoint, fetchUser } from "../../store/account/actions";

import {
  DICTIONARY_ROUTE_PATH,
  LANGUAGE_FREE_ROUTE_PATH,
  USER_SETTING_ROUTE_PATH,
  USER_LOGIN_ROUTE_PATH,
  USER_REGISTER_ROUTE_PATH,
} from "../../routes/constants";
import { Language } from "../../i18n";
import { LANGUAGE_OPTIONS } from "../../utils/constant";
import { numberWithCommas } from "../../utils/numberHandle";
import "./style.scss";
import { PRO_ADMIN_DASHBOARD_PATH } from "../../routes/constants";
import { getAuthToken } from "../../utils/index";
import { Button } from "../with-stories/AntPlus/Button";

const NavBar = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const user = useSelector(getUser);
  const language = useSelector(getLanguage);
  const { formatMessage } = useIntl();
  const location = useLocation();
  const token = getAuthToken();

  const googleClientId = String(process.env.REACT_APP_GOOGLE_CLIENT_ID);

  useEffect(() => {
    if (token) {
      dispatch(getUserPoint());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (token) {
      /**
       * Fetch user info when user navigates b/w pages.
       * This is needed so that we can show the correct
       * user details above in the navbar when there is
       * a user logged-in change in b/w the browser tabs.
       */
      dispatch(fetchUser());
    }
  }, [token, dispatch, location.pathname]);

  const { signOut: signOutWithGoogle } = useGoogleLogout({
    clientId: googleClientId,
  });

  const handleAccountMenu = (e: MenuInfo) => {
    switch (e.key) {
      case "logout":
        // Facebook logout execution `window.FB.logout()` is not needed as `window.FB` is not called outside our Login/Signup Pages.
        signOutWithGoogle();
        setTimeout(() => {
          dispatch(logout());
        }, 300);
        break;
    }
  };

  const onShowDrawer = () => {
    setVisible(true);
  };

  const onCloseDrawer = () => {
    setVisible(false);
  };

  const handleLanguageChange = (lang: Language) => {
    dispatch(setLanguage(lang));
  };

  const dropMenu = (
    <Menu onClick={handleAccountMenu}>
      <Menu.Item key="logout">
        <FormattedMessage
          id="settings-profiles/logout"
          defaultMessage="Logout"
        />
      </Menu.Item>
    </Menu>
  );

  const navMenu = (
    <>
      <Row>
        <Col xs={24} md={0}>
          {user.point && (
            <Link
              className="point-box-link"
              to={USER_SETTING_ROUTE_PATH}
              onClick={onCloseDrawer}
            >
              <div className="point-box">
                <div className="point-icon-container">
                  <FontAwesomeIcon className="point-icon" icon={faAward} />
                </div>
                <div className="point">
                  <div className="point-value">
                    {numberWithCommas(user.point)}
                  </div>
                  <div className="point-pts">pts</div>
                </div>
              </div>
            </Link>
          )}
        </Col>
      </Row>
      <div className="nav-menu-item">
        <NavLink to={LANGUAGE_FREE_ROUTE_PATH} onClick={onCloseDrawer}>
          <FormattedMessage
            id="settings-profiles/service"
            defaultMessage="Service"
          />
        </NavLink>
      </div>
      <div className="nav-menu-item">
        {user.isAdmin && (
          <NavLink to={DICTIONARY_ROUTE_PATH} onClick={onCloseDrawer}>
            <FormattedMessage
              id="settings-profiles/dictionary"
              defaultMessage="Dictionary"
            />
          </NavLink>
        )}
      </div>
      <div className="nav-menu-item">
        <NavLink to={USER_SETTING_ROUTE_PATH} onClick={onCloseDrawer}>
          <FormattedMessage
            id="settings-profiles/settings"
            defaultMessage="Settings"
          />
        </NavLink>
      </div>
    </>
  );

  const navMenuPublic = (
    <>
      <div className="nav-menu-item">
        <NavLink
          to={{
            pathname: USER_LOGIN_ROUTE_PATH,
            state: {
              prevLocation: location,
            },
          }}
          onClick={onCloseDrawer}
        >
          <Button variant="secondary" icon={<LoginOutlined />} shape="round">
            <span>
              <FormattedMessage id="nav/login" defaultMessage="Login" />
            </span>
          </Button>
        </NavLink>
      </div>
      <div className="nav-menu-item">
        <NavLink
          to={{
            pathname: USER_REGISTER_ROUTE_PATH,
            state: {
              prevLocation: location,
            },
          }}
          onClick={onCloseDrawer}
        >
          <Button variant="primary" icon={<UserOutlined />} shape="round">
            <span>
              <FormattedMessage id="nav/register" defaultMessage="Register" />
            </span>
          </Button>
        </NavLink>
      </div>
    </>
  );

  if (!token) {
    return (
      <>
        <Row>
          <Col xs={{ span: 0 }} md={{ span: 24 }}>
            <div
              className="navbar-container no-auth-navbar"
              style={{ alignItems: "center" }}
            >
              <h2 className="site-title">YouConnect</h2>
              <div className="nav-menu-container">
                <div className="nav-menu">
                  {navMenuPublic}
                  <div className="nav-menu-item">
                    <Row>
                      <Select
                        className="language-selector"
                        placeholder={formatMessage({
                          id: "settings-profile/language-placeholder",
                          defaultMessage: "Select Language",
                        })}
                        options={LANGUAGE_OPTIONS}
                        defaultValue={language}
                        onChange={handleLanguageChange}
                      />
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 0 }}>
            <div className="navbar-container-device">
              <h2 className="site-title">YouConnect</h2>
              <div className="nav-menu-device">{navMenuPublic}</div>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col xs={{ span: 0 }} md={{ span: 24 }}>
          <div
            className="navbar-container"
            style={
              user.isAdmin || user.isBusiness || user.isProAdmin
                ? { alignItems: "center" }
                : {}
            }
          >
            <h2 className="site-title">YouConnect</h2>
            {user.isProAdmin &&
              location.pathname === PRO_ADMIN_DASHBOARD_PATH && (
                <span className="business-logo-text">ProAdmin</span>
              )}
            <div className="nav-menu-container">
              <div className="nav-menu">
                {navMenu}
                <div className="nav-menu-item">
                  <Row>
                    <Select
                      className="language-selector"
                      placeholder={formatMessage({
                        id: "settings-profile/language-placeholder",
                        defaultMessage: "Select Language",
                      })}
                      options={LANGUAGE_OPTIONS}
                      defaultValue={language}
                      onChange={handleLanguageChange}
                    />
                    <Dropdown
                      overlay={dropMenu}
                      placement="bottomRight"
                      arrow
                      trigger={["click"]}
                    >
                      <div className="avatar">
                        <Avatar
                          size={48}
                          icon={<UserOutlined />}
                          src={user.avatar}
                        />
                      </div>
                    </Dropdown>
                  </Row>
                </div>
              </div>
              {user.point && (
                <Link className="point-box-link" to={USER_SETTING_ROUTE_PATH}>
                  <div className="point-box">
                    <div className="point-icon-container">
                      <FontAwesomeIcon className="point-icon" icon={faAward} />
                    </div>
                    <div className="point">
                      <div className="point-value">
                        {numberWithCommas(user.point)}
                      </div>
                      <div className="point-pts">pts</div>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 0 }}>
          <div className="navbar-container-device">
            <h2 className="site-title">YouConnect</h2>
            <div className="nav-menu-device">
              <div className="menu-item">
                <Dropdown
                  overlay={dropMenu}
                  placement="bottomRight"
                  arrow
                  trigger={["click"]}
                >
                  <div className="avatar">
                    <Avatar
                      size={33}
                      icon={<UserOutlined />}
                      src={user.avatar}
                    />
                  </div>
                </Dropdown>
              </div>
              <div className="menu-item" onClick={onShowDrawer}>
                <AppstoreFilled className="menu-icon" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Drawer
        placement="right"
        onClose={onCloseDrawer}
        visible={visible}
        closable={false}
      >
        <div className="drawer-container">{navMenu}</div>
      </Drawer>
    </>
  );
};

export default NavBar;
