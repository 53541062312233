import React, { ReactNode, HTMLAttributes } from "react";
import { Button as AntdButton } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { ButtonShape, ButtonType } from "antd/lib/button";
import classNames from "classnames";
import "./styles.scss";

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  /**
   * Provide a Text for the button?
   */
  children: ReactNode;
  /**
   * Which Variant would you like to use?
   */
  variant?: ButtonType | "secondary";
  /**
   * How large should the button be?
   */
  size?: SizeType;
  /**
   * Which Shape would you like to use?
   */
  shape?: ButtonShape;
  /**
   * What background color would you like use?
   */
  backgroundColor?: string;
  /**
   * Provide a icon for the button?
   */
  icon?: ReactNode;
  /**
   * Optional click handler?
   */
  onClick?: () => void;
  /**
   * Optional className?
   */
  className?: string;
}

/**
 * Primary UI component for user interaction,
 * Ant Design used as UI Library
 */
export const Button = ({
  children,
  variant = "default",
  size,
  shape,
  backgroundColor,
  icon,
  className = "",
  ...props
}: ButtonProps) => {
  return (
    <AntdButton
      type={variant === "secondary" ? "default" : variant}
      size={size}
      shape={shape}
      style={{ backgroundColor }}
      ghost={variant === "ghost"}
      className={`${className} ${classNames({
        "ant-btn-secondary": variant === "secondary",
      })}`}
      icon={icon}
      {...props}
    >
      {children}
    </AntdButton>
  );
};
