import { createSelector } from "reselect";
import { State } from "../state";
import { AccountState } from "./reducer";

export function getAccountState(state: State): AccountState {
  return state.account;
}

export const getUser = createSelector(
  getAccountState,
  (account: AccountState) => account.user
);
export const isUserLoaded = createSelector(
  getAccountState,
  (account: AccountState) => account.loaded
);
export const isAuthenticated = createSelector(
  getAccountState,
  (account: AccountState) => account.authenticated
);
export const getRedirectPath = createSelector(
  getAccountState,
  (account: AccountState) => account.redirectPath
);
export const accountErrors = createSelector(
  getAccountState,
  (account: AccountState) => account.errors
);
export const getIsSubmitting = createSelector(
  getAccountState,
  (account: AccountState) => account.isSubmitting
);
export const getBusinessMode = createSelector(
  getAccountState,
  (account: AccountState) => account.isBusinessMode
);
export const getBusinessUpdated = createSelector(
  getAccountState,
  (account: AccountState) => account.isBusinessUpdated
);
export const getPaymentMethod = createSelector(
  getAccountState,
  (account: AccountState) => account.paymentMethod
);
export const userAuthLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.userAuthLoading
);
