import { AccountActions } from "./actions";
import { AccountTypes, User, PlanTypes, IPaymentMethod } from "./types";
import { setSentryTag } from "../../utils/sentry";

export type AccountState = {
  user: User;
  loaded: boolean;
  isSubmitting: boolean;
  authenticated: boolean;
  redirectPath: string | null;
  errors: any[];
  isBusinessMode: boolean;
  isBusinessUpdated: boolean;
  paymentMethod: IPaymentMethod | null;
  userAuthLoading: boolean;
};

export const accountInitialState: AccountState = {
  user: {
    name: "",
    email: "",
    avatar: "",
    point: "",
    latestPointsExpireDate: "",
    plan: PlanTypes.free,
    selfExplorePlan: false,
    isAdmin: false,
    isProAdmin: false,
    isBusiness: false,
    stripeAccountId: null,
    partyPlan: false,
    therapyPlan: false,
    pointHistories: null,
    isFirst: false,
  },
  loaded: false,
  isSubmitting: false,
  authenticated: false,
  redirectPath: null,
  errors: [] as any[],
  isBusinessMode: false,
  isBusinessUpdated: false,
  paymentMethod: null,
  userAuthLoading: false,
};

export function accountReducer(
  state: AccountState = accountInitialState,
  action: AccountActions
): AccountState {
  switch (action.type) {
    case AccountTypes.SET_USER:
      const user: User = {
        ...state.user,
        ...action.payload.user,
      };
      setSentryTag(user);
      return {
        ...state,
        user,
        loaded: true,
      };
    case AccountTypes.SET_USER_PERMISSION:
      return {
        ...state,
        user: {
          ...state.user,
          isAdmin: action.payload.isAdmin,
        },
        loaded: true,
      };
    case AccountTypes.SET_USER_PRO_ADMIN_PERMISSION:
      const newUser: User = {
        ...state.user,
        ...action.payload.user,
      };

      return {
        ...state,
        user: newUser,
        loaded: true,
      };
    case AccountTypes.SET_PLAN:
      return {
        ...state,
        user: {
          ...state.user,
          plan: action.payload as any,
        },
      };
    case AccountTypes.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case AccountTypes.SET_REDIRECT_PATH:
      return { ...state, redirectPath: action.payload.redirectPath };
    case AccountTypes.SET_AUTHENTICATE:
      return { ...state, authenticated: action.payload.authenticated };
    case AccountTypes.LOGOUT:
      return {
        ...accountInitialState,
      };
    case AccountTypes.SET_ERRORS:
      return {
        ...state,
        errors: action.payload as any[],
        userAuthLoading: false,
      };
    case AccountTypes.GET_USERPOINT:
      return {
        ...state,
        user: {
          ...state.user,
          point: action.payload.user.remainingPoint,
          latestPointsExpireDate: action.payload.user.latestPointsExpireDate,
        },
      };
    case AccountTypes.GET_USERPOINT_HISTORY:
      return {
        ...state,
        user: {
          ...state.user,
          pointHistories: action.payload?.pointHistories,
        },
      };
    case AccountTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case AccountTypes.USER_AUTH_REQUEST:
      return {
        ...state,
        userAuthLoading: true,
      };
    case AccountTypes.USER_AUTH_SUCCESS:
      return {
        ...state,
        userAuthLoading: false,
      };
    default:
      return state;
  }
}
