import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Select } from "antd";

import { setLanguage } from "../../store/app/actions";
import { getLanguage } from "../../store/app/selectors";
import { Language } from "../../i18n";
import { getAuthToken } from "../../utils";
import { LANGUAGE_OPTIONS } from "../../utils/constant";
import {
  USER_LOGIN_ROUTE_PATH,
  USER_REGISTER_ROUTE_PATH,
  PRIVACY_POLICY_ROUTE_PATH,
  PRIVACY_POLICY_ROUTE_PATH_EN,
  TERMS_USE_ROUTE_PATH,
  TERMS_USE_ROUTE_PATH_EN,
  SCT_LAW_ROUTE_PATH,
  SCT_LAW_ROUTE_PATH_EN,
} from "../../routes/constants";

import { ReactComponent as Logo } from "../../assets/youconnect.svg";
import youconnectLogo from "../../images/homepage-logos/youconnect.png";

import "./style.scss";
import { useLocation } from "react-router-dom";
import { GlobalOutlined } from "@ant-design/icons";

const Footer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const language = useSelector(getLanguage);
  const token = getAuthToken();
  const queryParams = useLocation().search;
  const query = new URLSearchParams(queryParams);
  const refCode = query.get("ref") || "";

  const handleLanguageChange = (lang: Language) => {
    dispatch(setLanguage(lang));
  };

  const onLogin = () => {
    history.push({
      pathname: USER_LOGIN_ROUTE_PATH,
      ...(refCode ? { search: `?ref=${refCode}` } : {}),
    });
  };

  const onRegister = () => {
    history.push({
      pathname: USER_REGISTER_ROUTE_PATH,
      ...(refCode ? { search: `?ref=${refCode}` } : {}),
    });
  };

  return (
    <footer>
      <div className="footer-content">
        <div className="main-container">
          {language !== "ja" ? (
            <img
              src={youconnectLogo}
              alt="youconnectLogo"
              width="247"
              height="79"
            />
          ) : (
            <Logo />
          )}

          <div className="footer-links">
            <div className="services-menu">
              <h4>
                <FormattedMessage
                  id="label/services"
                  defaultMessage="Related Service"
                />
              </h4>
              <a
                rel="noreferrer"
                href={process.env.REACT_APP_DOKODEMODOA_LINK}
                target="_blank"
              >
                <FormattedMessage
                  id="label/DOKODEMO Door"
                  defaultMessage="DOKODEMO Door"
                />
              </a>
            </div>

            <div className="other-links">
              <h4>
                <FormattedMessage
                  id="label/other-link"
                  defaultMessage="Other Link"
                />
              </h4>
              <Link
                to={
                  language === "ja"
                    ? {
                        pathname: PRIVACY_POLICY_ROUTE_PATH,
                        ...(refCode ? { search: `?ref=${refCode}` } : {}),
                      }
                    : {
                        pathname: PRIVACY_POLICY_ROUTE_PATH_EN,
                        ...(refCode ? { search: `?ref=${refCode}` } : {}),
                      }
                }
              >
                <FormattedMessage
                  id="label/privacy-policy"
                  defaultMessage="Privacy Policy"
                />
              </Link>
              <Link
                to={
                  language === "ja"
                    ? {
                        pathname: TERMS_USE_ROUTE_PATH,
                        ...(refCode ? { search: `?ref=${refCode}` } : {}),
                      }
                    : {
                        pathname: TERMS_USE_ROUTE_PATH_EN,
                        ...(refCode ? { search: `?ref=${refCode}` } : {}),
                      }
                }
              >
                <FormattedMessage id="label/terms" defaultMessage="Terms" />
              </Link>
            </div>
          </div>

          <div className="extra-items">
            <div className="language-container">
              <GlobalOutlined />
              <span className="language">
                <FormattedMessage
                  id="label/language"
                  defaultMessage="Language"
                />
              </span>
            </div>
            <Select
              placeholder={formatMessage({
                id: "settings-profile/language-placeholder",
                defaultMessage: "Select Language",
              })}
              options={LANGUAGE_OPTIONS}
              value={language}
              onChange={handleLanguageChange}
            />

            {!token && (
              <div className="auth-buttons">
                <Button
                  type="default"
                  shape="round"
                  size="large"
                  onClick={onLogin}
                >
                  <FormattedMessage
                    id="label/sign-in"
                    defaultMessage="Sign In"
                  />
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={onRegister}
                >
                  <FormattedMessage
                    id="label/join-us"
                    defaultMessage="Join Us"
                  />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="copyright-content">
        <div className="main-container">
          <div className="footer-links">
            <Link
              to={
                language === "ja"
                  ? {
                      pathname: TERMS_USE_ROUTE_PATH,
                      ...(refCode ? { search: `?ref=${refCode}` } : {}),
                    }
                  : {
                      pathname: TERMS_USE_ROUTE_PATH_EN,
                      ...(refCode ? { search: `?ref=${refCode}` } : {}),
                    }
              }
            >
              <FormattedMessage
                id="label/terms-of-use"
                defaultMessage="Terms of Use"
              />
            </Link>
            <Link
              to={
                language === "ja"
                  ? {
                      pathname: PRIVACY_POLICY_ROUTE_PATH,
                      ...(refCode ? { search: `?ref=${refCode}` } : {}),
                    }
                  : {
                      pathname: PRIVACY_POLICY_ROUTE_PATH_EN,
                      ...(refCode ? { search: `?ref=${refCode}` } : {}),
                    }
              }
            >
              <FormattedMessage
                id="label/privacy-policy"
                defaultMessage="Privacy Policy"
              />
            </Link>
            <Link
              to={
                language === "ja"
                  ? {
                      pathname: SCT_LAW_ROUTE_PATH,
                      ...(refCode ? { search: `?ref=${refCode}` } : {}),
                    }
                  : {
                      pathname: SCT_LAW_ROUTE_PATH_EN,
                      ...(refCode ? { search: `?ref=${refCode}` } : {}),
                    }
              }
            >
              <FormattedMessage
                id="label/transaction-act"
                defaultMessage="Nortion based on the Specified Commercial Trasaction Act"
              />
            </Link>
          </div>
          <p className="copyright-text">
            Copyright &copy;MATRIX All right reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
