import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from "antd";
import { USER_LOGIN_ROUTE_PATH } from "./constants";
import { RouterProps } from "./types";
import { getAuthToken } from "../utils";

import { Loader } from "../components/Loader/Loader";
import NavBar from "../components/NavBar";

import { isUserLoaded } from "../store/account/selectors";
import PageFooter from "../components/Footer";

const { Content } = Layout;

interface PrivateRouteProps extends RouterProps {
  isWithoutNavbar?: boolean;
}

export function PrivateRoute({
  isWithoutNavbar = false,
  component: Component,
  ...rest
}: PrivateRouteProps) {
  const userLoaded = useSelector(isUserLoaded);
  const token = getAuthToken();
  return (
    <Route
      {...rest}
      render={({ location, match }) => {
        if (!token) {
          return (
            <Redirect
              to={{
                pathname: USER_LOGIN_ROUTE_PATH,
                state: { from: location },
              }}
            />
          );
        }

        if (!userLoaded) {
          return <Loader />;
        }

        if (isWithoutNavbar) {
          return (
            <>
              <Component match={match} />
            </>
          );
        }

        return (
          <>
            <div className="main-container">
              <NavBar />
              <Content>
                <Component match={match} />
              </Content>
            </div>
            <div>
              <PageFooter />
            </div>
          </>
        );
      }}
    />
  );
}
