import { createIntl, createIntlCache, IntlShape } from "react-intl";

import { displayLanguages } from "../utils/languages";
import en from "./languages/en.json";
import ja from "./languages/ja.json";
import zhCN from "./languages/zh-CN.json";
import zhTW from "./languages/zh-TW.json";

type Message = typeof en[number];

function convertToKeyValuePair(strings: Message[]) {
  return Object.fromEntries(strings.map((str) => [str.id, str.defaultMessage]));
}

export const messages = {
  en: convertToKeyValuePair(en),
  ja: convertToKeyValuePair(ja),
  "zh-CN": convertToKeyValuePair(zhCN),
  "zh-TW": convertToKeyValuePair(zhTW),
};

export type Language = keyof typeof messages;
export const defaultLanguage = displayLanguages.default;

const intlCache = createIntlCache();

export let intl: IntlShape;

export function makeIntl(language: any, messages: any) {
  intl = createIntl(
    {
      locale: language,
      messages: messages[language],
    },
    intlCache
  );
  return intl;
}

export function getBrowserLanguage() {
  return window?.navigator?.language || "en";
}

export function getAppLanguageByBrowserLanguage(): Language {
  const browserLanguage = getBrowserLanguage();

  if (/^ja\b/.test(browserLanguage)) {
    return "ja"; // Japanese
  }

  if (/^zh-TW/.test(browserLanguage)) {
    return "zh-TW"; // Chinese Traditional
  }

  if (/^zh\b/.test(browserLanguage)) {
    return "zh-CN"; // Chinese Simplified
  }

  return "en"; // English, as default
}
