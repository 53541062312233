import React, { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { Layout } from "antd";
import classNames from "classnames";
import { LANGUAGE_FREE_ROUTE_PATH } from "./constants";
import { RouterProps } from "./types";
import { getAuthToken } from "../utils";
import { isValidUrl } from "../utils/validation";
import { redirectToRefer } from "../utils/helpter";
import NavBar from "../components/NavBar";

import PageFooter from "../components/Footer";

const { Content } = Layout;

export interface PublicRouterProps extends RouterProps {
  redirect?: boolean;
  hasReferer?: boolean;
  noAuthDoor?: boolean;
}

export function PublicRoute({
  component: Component,
  redirect = false,
  hasReferer = false,
  noAuthDoor = false,
  ...rest
}: PublicRouterProps) {
  const token = getAuthToken();

  const query = new URLSearchParams(useLocation().search);
  const referer = query.get("referer") || "";
  useEffect(() => {
    if (!!token && isValidUrl(referer)) {
      redirectToRefer(referer, token);
    }
  }, [referer, token]);

  return (
    <Route
      {...rest}
      render={({ location, match }) => {
        if (!!token && redirect) {
          return (
            <Redirect
              to={{
                pathname: LANGUAGE_FREE_ROUTE_PATH,
                state: { from: location },
              }}
            />
          );
        }

        if (noAuthDoor) {
          return (
            <>
              <div
                className={classNames("main-container", {
                  "main-container--modern-design":
                    location.pathname === "/rooms" &&
                    location.search.includes("?menu=VR-Trip-Guide-Meetup"),
                })}
              >
                <NavBar />
                <Content>
                  <Component match={match} />
                </Content>
              </div>
              <div>
                <PageFooter />
              </div>
            </>
          );
        }

        return (
          <Content>
            <Component match={match} />
          </Content>
        );
      }}
    />
  );
}
