export interface RoomInterface {
  id: string;
  name: string;
  roomId: string;
  key: string;
  url: string;
  languages: string;
}
// id: number;
// defaultRoomId: string;
// pcUrl: string | null;
// s3Url: string | null;
// difficulty: string | null;

export interface FavouriteVideo {
  id: number;
  name: string;
  size: number;
  thumbnailUrl: string;
  roomId: string;
  resolution: string;
  youtubeUrl: string;
  streamUrl: string;
  streamCloudFrontUrl: string;
  s3Url: string;
  cloudFrontUrl: string;
  pcUrl: string;
  pcCloudFrontUrl: string;
  p480Url: string;
  p480CloudFrontUrl: string;
  rawStorage: string;
  pcStreamUrl: string;
  defaultRoomId: string;
  difficulty: string | null;
}

export interface RegisterTourVideo {
  active: boolean;
  backupUrl: string;
  defaultRoomId: string;
  description: string;
  id: number;
  isPreview: boolean;
  name: string;
  ownedByTDX: boolean;
  p480Url: string | null;
  pcStreamUrl: string | null;
  pcUrl: string | null;
  publish: boolean;
  rawStorage: string | null;
  resolution: string;
  s3Url: string | null;
  streamUrl: string | null;
  userId: string | null;
  difficulty: string | null;
}

export interface VUMSVideo {
  active: boolean;
  backupUrl: string;
  defaultRoomId: string;
  description: string;
  id: number;
  isPreview: boolean;
  name: string;
  ownedByTDX: boolean;
  p480Url: string | null;
  pcStreamUrl: string | null;
  pcUrl: string | null;
  publish: boolean;
  rawStorage: string | null;
  resolution: string;
  s3Url: string | null;
  streamUrl: string | null;
  userId: string | null;
  difficulty: string | null;
}

export interface StreamingTestVideo {
  active: boolean;
  backupUrl: string;
  defaultRoomId: string;
  description: string;
  id: number;
  isPreview: boolean;
  name: string;
  ownedByTDX: boolean;
  p480Url: string | null;
  pcStreamUrl: string | null;
  pcUrl: string | null;
  publish: boolean;
  rawStorage: string | null;
  resolution: string;
  s3Url: string | null;
  streamUrl: string | null;
  userId: string | null;
}
export interface TrialVideo {
  id?: number;
  video?: string;
}

export interface PartyRoomInterface {
  Video: any;
  id: string;
  videoId: string;
  key: string;
  url: string;
}

export interface TherapyRoomInterface {
  Video: any;
  id: string;
  videoId: string;
  title: string;
  key: string;
  url: string;
  photos: string[];
}

export interface IHistory {
  id: string;
  roomId: string;
  roomName: string;
  userName: string;
  action: string;
  createdAt: string;
}

export enum RoomActions {
  GET_ROOMS = "room/GET_ROOMS",
  GET_SELECTED_VIDEO = "room/GET_SELECTED_VIDEO",
  GET_FAVOURITE_VIDEOS = "room/GET_FAVOURITE_VIDEOS",
  GET_REGISTER_TOUR_VIDEO = "room/GET_REGISTER_TOUR_VIDEO",
  SET_ERRORS = "room/SET_ERRORS",
  CLEAR_ERRORS = "room/CLEAR_ERRORS",
  SET_IS_SUBMITTING = "room/SET_IS_SUBMITTING",
  CREATE_ROOM = "room/CREATE_ROOM",
  UPDATE_ROOM = "room/UPDATE_ROOM",
  DELETE_ROOM = "room/DELETE_ROOM",
  UPDATE_VIDEO_HOST = "room/UPDATE_VIDEO_HOST",
  GET_VUMS_VIDEOS = "room/GET_VUMS_VIDEOS",
  GET_PARTY_ROOMS = "room/GET_PARTY_ROOMS",
  GET_THERAPY_ROOMS = "room/GET_THERAPY_ROOMS",
  GET_STREAMING_TEST_VIDEO_PUBLIC = "room/GET_STREAMING_TEST_VIDEO_PUBLIC",
  GET_STREAMING_TEST_VIDEO = "room/GET_STREAMING_TEST_VIDEO",
  SET_STREAMING_TEST_VIDEO = "room/SET_STREAMING_TEST_VIDEO",
  SET_TRIAL_VIDEO = "room/SET_TRIAL_VIDEO",
  SET_ROOM_HISTORY = "room/SET_ROOM_HISTORY",
}
