import React from "react";
import { useSelector } from "react-redux";
import { RawIntlProvider } from "react-intl";

import { Layout } from "antd";
import { makeIntl, messages } from "./i18n";
import { AppLoader } from "./components/AppLoader";
import { getLanguage } from "./store/app/selectors";
import { AppRouter } from "./routes/AppRouter";

import "intro.js/introjs.css";
import "./App.scss";
import { BreakpointProvider } from "./breakpoint";

function App() {
  const language = useSelector(getLanguage);
  const intl = makeIntl(language, messages);

  // taken from ./variables.scss
  const queries = {
    xs: "(max-width: 576px)",
    sm: "(min-width: 576px)",
    md: "(max-width: 768px)",
    lg: "(max-width: 992px)",
    xl: "(max-width: 1200px)",
    xxl: "(max-width: 1600px)",
  };

  return (
    <RawIntlProvider value={intl}>
      <BreakpointProvider queries={queries}>
        <Layout className="h-100">
          <AppRouter />
        </Layout>
        <AppLoader />
      </BreakpointProvider>
    </RawIntlProvider>
  );
}

export default App;
