export enum PlanTypes {
  free = "free",
  pro = "pro",
  security = "security",
}

export interface User {
  id?: number;
  language?: string;
  name: string;
  email: string;
  point: string;
  latestPointsExpireDate: string;
  plan: PlanTypes;
  selfExplorePlan: boolean;
  isAdmin: boolean;
  isProAdmin: boolean;
  avatar?: string;
  isBusiness: boolean;
  stripeAccountId: string | null;
  partyPlan: boolean;
  therapyPlan: boolean;
  pointHistories: PointHistories | null;
  isFirst: boolean;
}

export interface PointHistories {
  total: number;
  pointHistories: Array<PointHistory>;
}

export interface PointHistory {
  id: number;
  isFree: boolean;
  expirationDate?: string;
  amount: number;
  createdAt: string;
}

export interface UserPoint {
  email: string;
  name: string;
  remainingPoint: string;
  latestPointsExpireDate: string;
  userId: string;
}

export interface SubscribePlanWithFreePoint {
  isFree: boolean;
  eventCode: string;
  amountChange: number;
  planCode: string;
}

export interface IPaymentMethod {
  id: string;
  last4: string;
  exp_year: number;
  exp_month: number;
  country: string;
}

export enum AccountTypes {
  SET_USER = "account/SET_USER",
  SET_USER_PERMISSION = "account/SET_USER_PERMISSION",
  SET_USER_PRO_ADMIN_PERMISSION = "account/SET_USER_PRO_ADMIN_PERMISSION",
  SET_PLAN = "account/SET_PLAN",
  SET_IS_SUBMITTING = "account/SET_IS_SUBMITTING",
  SET_AUTHENTICATE = "account/SET_AUTHENTICATE",
  SET_REDIRECT_PATH = "account/SET_REDIRECT_PATH",
  LOGOUT = "account/LOGOUT",
  REQUEST_LOGIN = "account/REQUEST_LOGIN",
  REQUEST_REGISTER = "account/REQUEST_REGISTER",
  SET_ERRORS = "account/SET_ERRORS",
  GET_USERPOINT = "account/GET_USERPOINT",
  GET_USERPOINT_HISTORY = "account/GET_USERPOINT_HISTORY",
  SUBSCRIBE_PLAN_WITH_FREE_POINT = "account/SUBSCRIBE_PLAN_WITH_FREE_POINT",
  SET_PAYMENT_METHOD = "account/SET_PAYMENT_METHOD",
  USER_AUTH_REQUEST = "account/USER_AUTH_REQUEST",
  USER_AUTH_SUCCESS = "account/USER_AUTH_SUCCESS",
}
