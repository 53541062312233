import { AppActionTypes } from "./actions";
import { AppActions, PointEmail } from "./types";
import { getAppLanguageByBrowserLanguage, Language } from "../../i18n";

export const appInitialState = {
  language: (localStorage.getItem("language") ||
    getAppLanguageByBrowserLanguage() ||
    "en") as Language,
  loading: false as boolean,
  pointEmail: {} as PointEmail,
};

export type AppState = typeof appInitialState;

export function appReducer(
  state: AppState = appInitialState,
  action: AppActionTypes
): AppState {
  switch (action.type) {
    case AppActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AppActions.SET_LANGUAGE:
      localStorage.setItem("language", action.payload);

      return {
        ...state,
        language: action.payload,
      };
    case AppActions.GET_POINT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        pointEmail: action.payload,
      };
    default:
      return state;
  }
}
