import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";

import { setLanguage } from "../../../store/app/actions";
import { getLanguage } from "../../../store/app/selectors";

import { Language } from "../../../i18n";
import { LANGUAGE_OPTIONS } from "../../../utils/constant";

import "../style.scss";
import classNames from "classnames";
import { getAuthToken } from "../../../utils";

interface IntroProps {
  readonly isBlur: boolean;
  readonly isTranslation?: boolean;
  readonly children: React.ReactNode;
  readonly withNoImageBackground?: boolean;
  readonly withCustomScrollbar?: boolean;
}

const IntroContainer = ({
  isBlur,
  children,
  isTranslation = true,
  withNoImageBackground = false,
  withCustomScrollbar = false,
}: IntroProps) => {
  const dispatch = useDispatch();
  const language = useSelector(getLanguage);
  const token = getAuthToken();

  const handleLanguageChange = (lang: Language) => {
    if (token) {
      dispatch(setLanguage(lang));
    } else {
      dispatch(setLanguage(lang, true));
    }
  };

  return (
    <div
      className={classNames("intro-container", {
        "custom-scrollbar": withCustomScrollbar,
      })}
    >
      <div
        className={classNames("intro-background", {
          "blur-effect-on": isBlur,
          "background-no-image": withNoImageBackground,
        })}
      />

      {children}

      {isTranslation && (
        <div className="language-selector">
          <label>
            <FormattedMessage
              id="choose-language"
              defaultMessage="Choose Language"
            />
          </label>

          <Select
            style={{ width: 120 }}
            options={LANGUAGE_OPTIONS}
            defaultValue={language}
            onChange={handleLanguageChange}
          />
        </div>
      )}
    </div>
  );
};

export default IntroContainer;
